<!-- 模板 -->
<template>
	<div class="">
		<div class="contant">
			<headers class="top" :active="2"></headers>
			<img class="imhg" src="../assets/image/19.png" alt="" />
			<div class="biao">
				<div class="wu ge">
					<div class="zu">
						<div class="line"></div>
						<div class="tit">{{ case_list.name }}</div>
						<div class="line"></div>
					</div>
					<div class="chi">File Download</div>
					<div class="trophy">
						<div class="trophy_l">
							<div v-for="(it, i) in case_qieh" @click="pai(it, i)" :key="i"
								:class="current == i ? 'tit tits' : 'tit'">
								{{ it.name }}
							</div>
						</div>
					</div>
					<div class="forms">
						<div class="fores_top">
							<span class="n_name">文件名</span>
							<span class="n_time">上传时间</span>
							<span class="n_cao">操作</span>
						</div>

						<div class="rt">
							<div v-for="city in cities" :key="city" class="at">
								<span class="n_name">{{ city.title }}</span>
								<span class="n_time">{{ city.publishtime }}</span>
								<span class="n_cao" @click="dowload($baseUrl + city.downloadfile)">下载</span>
							</div>
						</div>

						<!-- 分页 -->
						<div class="pagees">
							<!-- <el-button type="info" plain>一键下载</el-button> -->
							<el-pagination @current-change="next" background layout="prev, pager, next" :total="total">
							</el-pagination>
						</div>
					</div>
				</div>
				<img class="img_one" src="../assets/image/5.png" alt="" />
				<img class="img_twos" src="../assets/image/5.png" alt="" />
			</div>
			<footers></footers>
		</div>
	</div>
</template>

<script>
	import headers from "../assembly/head.vue"; //头部
	import request from "../utils/request.js";
	import footers from "@/assembly/footer"; //页脚
	const cityOptions = [];

	export default {
		name: "",
		data() {
			return {
				current: 0,
				checkAll: false,
				checkedCities: [],
				cities: [],
				isIndeterminate: true,
				case_list: {},
				total: 0,
				case_qieh: {},
				page: 1,
			};
		},
		props: [],
		components: {
			headers,
			footers,
		},
		computed: {},
		filters: {},
		methods: {
			next() {
				this.case();
			},
			dowload(e) {
				window.open(e);
			},
			async case () {
				let options = {
					url: "/index/getContentByChannel",
					type: "post",
					data: {
						channel_id: 13,
						page: this.page,
					},
				};
				try {
					let res = await request(options);

					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
					this.case_list = res.data.data;
					this.case_qieh = this.case_list.child;
					this.total = res.data.data.child[0].content.length;
					let data = res.data.data.child[0].content;

					this.cities = this.cities.concat(data);
				} catch (error) {
					this.$message.error("请求数据失败");
				}
			},
			handleCheckAllChange(val) {
				this.checkedCities = val ? cityOptions : [];
				this.isIndeterminate = false;
			},
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate =
					checkedCount > 0 && checkedCount < this.cities.length;
			},
			pai(it, i) {
				this.cities = it.content;
				this.total = it.content.length;
				this.current = i;
			},
		},
		created() {
			this.case();
		},
		mounted() {
			let _this = this;
			// 页面渲染结束再执行动画
			this.$nextTick(function() {
				new _this.WOW({
					live: false
				}).init();
			});
		},
	};
</script>

<style lang="less" scoped>
	//@import url(); 引入公共css类
	.contant {
		position: relative;

		.top {
			width: 100%;
			// position: fixed;
			top: 0;
			position: sticky;
			z-index: 10000;
		}

		.imhg {
			width: 100%;
			display: block;
		}

		.biao {
			position: relative;
			padding-bottom: 210px;
			overflow: hidden;

			.img_one {
				width: 360px;
				position: absolute;
				top: 50%;
				left: -190px;
				// margin-left: -190px;
			}

			.img_twos {
				position: absolute;
				width: 360px;
				right: -150px;
				bottom: 12%;
				// margin-right: -150px;
			}

			.ge {
				padding-top: 60px;
				overflow: hidden;

				.zu {
					display: flex;
					align-items: center;
					justify-content: center;

					.line {
						width: 120px;
						height: 2px;
						background: #776360;
						opacity: 1;
					}

					.tit {
						font-size: 40px;
						font-weight: bold;
						line-height: 68px;
						color: #776360;
						letter-spacing: 50px;
						opacity: 1;
						padding-left: 40px;
						margin: 0 30px;
						
					}
				}

				.chi {
					text-align: center;
					font-size: 16px;
					font-weight: 400;
					line-height: 27px;
					color: #776360;
				}

				.trophy {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 60px;

					.trophy_l {
						display: flex;

						.tit {
							// width: 80px;
							padding: 0 20px;
							height: 40px;
							background: #e2dfde;
							opacity: 1;
							border-radius: 20px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 20px;
							font-size: 20px;
							font-weight: 400;
							color: #252121;
							cursor: pointer;
						}

						.tits {
							background-color: #776360 !important;
							color: #fff !important;
						}
					}
				}

				.forms {
					margin-top: 20px;

					.fores_top {

						padding: 10px 60px;
						background-image: url("../assets/image/26.png");
						background-repeat: no-repeat;
						background-size: 100% 100%;

						.n_name {
							margin-left: 30px;
							width: 50%;
							display: inline-block;
							font-size: 16px;
							font-weight: 400;
							letter-spacing: 4px;
							color: #ffffff;
						}

						.n_time {
							font-size: 16px;
							font-weight: 400;
							color: #ffffff;
							width: 24%;
							letter-spacing: 4px;
							display: inline-block;
							text-align: center;
						}

						.n_cao {
							font-size: 16px;
							font-weight: 400;
							letter-spacing: 4px;
							color: #ffffff;
							width: 20%;
							display: inline-block;
							text-align: center;
						}
					}

					/deep/.el-checkbox__label {
						width: 100%;
						display: block;
					}

					.el-checkbox {
						margin-right: 0;
					}

					.rt {
						// padding: 0px ;
						padding: 0 60px;
						box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

						.is-checked {
							background: rgba(188, 173, 170, 0.39) !important;

							.n_cao {
								color: #776360 !important;
							}
						}

						.at {
							flex: 1;
							display: flex;
							align-items: center;
							padding: 20px 0px;
							border-bottom: 1px solid #ccc;

							&:last-child {
								border-bottom: none;
							}

							.el-checkbox__label {
								flex: 1;
							}

							// border-bottom: 1px solid #f5f4f4;
							.n_name {
								padding-left: 30px;
								display: inline-block;
								font-size: 16px;
								font-weight: 400;
								color: #706d6d;
								width: 50%;

							}

							.n_time {
								font-size: 16px;
								font-weight: 400;
								color: #706d6d;
								width: 24%;
								display: inline-block;
								text-align: center;
							}

							.n_cao {
								font-size: 16px;
								font-weight: 400;
								color: #2172eb;
								cursor: pointer;
								width: 20%;
								display: inline-block;
								text-align: center;
							}
						}
					}

					.pagees {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 22px;
						// margin-bottom: 211px;
					}
				}
			}
		}
	}
</style>
<style>
	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
</style>
